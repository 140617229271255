<template>
    <div class="alert alert-info">{{ message_erreur }}</div>
</template>

<script type="text/javascript">
	export default {
        props:[
            'messageI18n',
        ],
		computed: {
            message_erreur: function() {
                return this.getTrad(this.messageI18n)
            }
		},
	}
</script>
